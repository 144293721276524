import type * as Brink from 'types/vendors/brink'

export function useLastRawCart() {
  const lastRawCart = useState<Brink.Cart | undefined>('lastRawCart', () => (undefined))

  watchEffect(() => {
    console.log('lastRawCart', lastRawCart.value)
  })

  return { lastRawCart }
}
