import { isValidAddress, isValidContact } from 'lib/validation/contact'
import { getByCountryCode, patchByCountryCode } from 'lib/localStorage'
import { ORDER_SESSION } from 'configuration/global.configuration'

import type { OrderInformation } from 'types/checkout'
import type { CoreOptions } from '@adyen/adyen-web/dist/types/core/types'
import { AdyenCheckout, PayPal, type PayPalConfiguration } from '@adyen/adyen-web'
import type * as Brink from 'types/vendors/brink'
import { getAdyenClientKey, getAdyenEnvironment, onAdyenSessionError } from '~/lib/adyen'
import type { ShippingMethodName } from '~/types/cart'
import { useApplePay } from '~/composables/useApplePay'
import { Order } from '~/types/models/order'
import { useAdyenSupportFunctions } from '~/composables/useAdyenSupportFunctions'

// import { CartState } from './cart'

// function checkIfOrderIsBlocked(items: CartState['items'] | undefined) {
//   if (!items) return false

//   const blockedItemSkus: string[][] = [[]]

//   const itemSkus = items.map(item => item.parentSku)

//   const shouldBlockOrder = blockedItemSkus.some(blockedItemSku =>
//     itemSkus.every(itemSku => blockedItemSku.includes(itemSku))
//   )

//   return shouldBlockOrder
// }

export const useOrderStore = defineStore('OrderStore', () => {
  const orderLoading = ref(false)
  const session = ref<OrderInformation | undefined>(undefined)
  const adyenSupportFunctions = useAdyenSupportFunctions()
  const expressOrderApplePay = ref<Order | undefined>(undefined)

  const context = useNuxtApp()
  const checkoutStore = useCheckoutStore()
  const storefrontStore = useStorefrontStore()
  const shippingStore = useShippingStore()
  const { methods: shippingMethods } = storeToRefs(shippingStore)
  const {
    public: { adyenEnvironment, adyenClientKey, adyenClientKeyUs },
  } = useRuntimeConfig()

  const createLocalStore = () => {
    let state: any = null

    return {
      setState(value: object) {
        state = { ...state, ...value }
      },
      getState() {
        return state
      },
    }
  }
  const store = createLocalStore()

  const setSession = (value: OrderInformation | undefined) => {
    patchByCountryCode(storefrontStore.currentMarketCountryCode, ORDER_SESSION, value)
    session.value = value
  }

  const setSessionFromLocalStorage = () => {
    session.value = getByCountryCode<OrderInformation>(
      storefrontStore.currentMarketCountryCode,
      ORDER_SESSION,
    )
  }

  function updateSessionPaymentMethod(paymentMethod: string) {
    if (!session.value)
      return

    setSession({
      ...session.value,
      order: {
        ...session.value.order,
        payment: {
          ...session.value.order.payment,
          method: paymentMethod,
        },
      },
    })
  }

  const getAndValidateCheckoutInformation = () => {
    const {
      contact,
      address: { shipping: shippingAddress, useShippingForBilling },
    } = checkoutStore.information
    let { billing: billingAddress } = checkoutStore.information.address

    if (useShippingForBilling)
      billingAddress = shippingAddress

    const { language: languageCode } = storefrontStore.currentMarket

    if (!isValidAddress(shippingAddress))
      throw new EvalError('Invalid or incomplete shipping address')
    if (!isValidAddress(billingAddress))
      throw new EvalError('Invalid or incomplete billing address')
    if (!isValidContact(contact))
      throw new EvalError('Invalid or incomplete contact object')

    return { shippingAddress, billingAddress, languageCode, contact }
  }

  const createOrderSession = async (returnUrl: string): Promise<OrderInformation> => {
    // Activate during fraud attacks to stop the attacker from creating orders
    // const cartStore = useCartStore()
    // const orderIsBlocked = checkIfOrderIsBlocked(cartStore.state.items)

    // if (orderIsBlocked) {
    //   return Promise.reject(new Error())
    // }

    orderLoading.value = true

    try {
      const { shippingAddress, billingAddress, languageCode, contact }
        = getAndValidateCheckoutInformation()

      const { order, session } = await context.$payments.createPaymentSession({
        shippingAddress,
        billingAddress,
        languageCode,
        returnUrl,
        contact,
      })

      const data = {
        order,
        session,
      }

      setSession(data)

      return data
    }
    catch (e) {
      // Most likely there's no cart session
      // if (e.code === UNAUTHORIZED) {
      //   return Promise.reject(e)
      // }

      console.error('Failed to create an Adyen Payment Session', e)
      return Promise.reject(e)
    }
    finally {
      orderLoading.value = false
    }
  }

  /**
   * Retrieve the session and order info from the session storage
   */
  const restoreOrCreateSession = (returnUrl: string): Promise<OrderInformation> => {
    if (session.value)
      return Promise.resolve(session.value)

    return createOrderSession(returnUrl)
  }

  const confirmOrder = async (signature: string, orderId: string) => {
    try {
      const order = await context.$payments.confirmPaymentSession(signature, orderId)

      return order
    }
    catch (error) {
      return Promise.reject(error)
    }
  }

  async function createAdvancedFlowApplePay(expressError: Ref<string>, amount: { currency: string, value: number }, emit: any, orderSignature: globalThis.Ref<any, any>) {
    console.log('createAdvancedFlowApplePay 1')
    const applePay = await useApplePay()
    console.log('createAdvancedFlowApplePay 2')
    const resp = await applePay.createAdvancedFlowApplePay(expressError, amount, emit, orderSignature)
    console.log('createAdvancedFlowApplePay 3')
    return resp
  }

  async function setUpApplePayExpress(checkout: any, expressError: Ref<string>) {
    console.log('setUpApplePayExpress 1')
    const _applePay = await useApplePay()
    console.log('setUpApplePayExpress 2')

    const applePay = await _applePay.setUpApplePayExpress(checkout, expressError)
    console.log('setUpApplePayExpress 3')
    return applePay
  }

  const createAdvancedFlowPaypal = async (returnUrl: string, amount: { currency: string, value: number }, emit: any, orderSignature: globalThis.Ref<any, any>) => {
    const { storefrontCode } = storefrontStore.current
    const { locale, countryCode } = storefrontStore.currentMarket
    const channel = 'Web'
    const languageCode = adyenSupportFunctions.getAdyenLanguage(locale.html)
    const cartStore = useCartStore()
    const orderStore = useOrderStore()

    // 1. Get payment methods
    const paymentMethodsResponse = await context.$payments.getPaymentMethods({ countryCode, languageCode, channel })

    // 2. Create Config
    const clientKey = getAdyenClientKey(storefrontCode, {
      us: adyenClientKeyUs,
      default: adyenClientKey,
    })

    const getEnvironment = () => getAdyenEnvironment(storefrontStore.currentMarketCountryCode, adyenEnvironment)

    try {
      const checkoutConfiguration: CoreOptions = {
        clientKey,
        environment: getEnvironment(),
        amount,
        locale: adyenSupportFunctions.getAdyenLanguage(locale.html),
        countryCode,
        paymentMethodsResponse,
        // merchantAccount,
        onSubmit: async (state: any, component: any, actions: any) => {
          console.log('state', state)
          console.log('component', component)

          const cartResponse = await context.$payments.requestCart()

          try {
            let orderBody: Brink.AdyenCartToAdyenOrderBody
            if (cartResponse.shipping === undefined) {
              const deliveryMethods = await adyenSupportFunctions.createShippingMethodListPayPal(countryCode, component.props.amount.currency)
              await cartStore.addShippingMethodToCart(deliveryMethods[0].reference as ShippingMethodName)

              orderBody = {
                shippingAddress: {
                  country: countryCode,
                  city: 'Solna',
                  phone: '0761111111',
                  streetAddress: 'Lövgatan',
                  houseNumberOrName: '55',
                  familyName: 'Tran Luu',
                  postalCode: '16932',
                  givenName: 'Nicole',
                  region: 'Stockholm',
                },
                billingAddress: {
                  country: countryCode,
                  city: 'Solna',
                  phone: '0761111111',
                  streetAddress: 'Lövgatan',
                  houseNumberOrName: '55',
                  familyName: 'Tran Luu',
                  postalCode: '16932',
                  givenName: 'Nicole',
                  region: 'Stockholm',
                },
                email: 'user@example.com',
                returnUrl: location.href,
              }
            }
            else {
              const newShippingAddress = store.getState().shippingAddress
              console.log('address', newShippingAddress)
              orderBody = {
                shippingAddress: {
                  country: countryCode,
                  city: 'Solna',
                  phone: '0761111111',
                  streetAddress: 'Lövgatan',
                  houseNumberOrName: '55',
                  familyName: 'Tran Luu',
                  postalCode: '16932',
                  givenName: 'Nicole',
                  region: 'Stockholm',
                },
                billingAddress: {
                  country: countryCode,
                  city: 'Solna',
                  phone: '0761111111',
                  streetAddress: 'Lövgatan',
                  houseNumberOrName: '55',
                  familyName: 'Tran Luu',
                  postalCode: '16932',
                  givenName: 'Nicole',
                  region: 'Stockholm',
                },
                email: 'user@example.com',
                returnUrl: location.href,
              }
            }

            const orderResponse = await context.$payments.createCartOrders(orderBody)

            const body: Brink.AdyenPaymentRequestBody = {
              redirect: {
                canceled: location.href,
                default: location.href,
                success: location.href,
                error: location.href,
              },
              orderId: orderResponse.id,
              origin: location.href,
              paymentMethod: state.data.paymentMethod,
              shopperLocale: locale.html,
              browserInfo: {},
            }
            console.log('order response', orderResponse)
            const paymentResponse = await context.$payments.requestPayments(body)

            store.setState({ pspReference: paymentResponse.paymentResult.pspReference, order: orderResponse })

            if (!paymentResponse.paymentResult.resultCode) {
              console.log('action rejected')
              actions.reject()
              return
            }

            const {
              resultCode,
              action,
              order,
              donationToken,
            } = paymentResponse.paymentResult

            console.log('result', resultCode)
            console.log('action', action)
            console.log('order', order)
            console.log('donationToken', donationToken)
            actions.resolve({
              resultCode,
              action,
              order,
              donationToken,
            })
          }
          catch (error) {
            console.error('onSubmit', error)
            actions.reject()
          }
        },
        onAdditionalDetails: async (state: any, component: any) => {
          // Only for Paypal
          console.log('on additional details', state, component)
          if (component.props.type === 'paypal') {
            console.log('additional details')
          }
        },
        onPaymentCompleted: async (result: Adyen.PaymentSessionResponse, component: any) => {
          console.log('done', result)
          if (orderSignature.value) {
            try {
              const order = store.getState().order
              console.log('order', order)
              const { id } = await orderStore.confirmOrder(orderSignature.value, order.id)
              return adyenSupportFunctions.handleServerResponse(result, component, id)
            }
            catch (error) {
              emit('paymentError')
              console.error(error)
              checkoutStore.loading = false
            }
          }
          else {
            const order = store.getState().order
            return adyenSupportFunctions.handleServerResponse(result, component, order.id)
          }
        },
        onPaymentFailed: async (result: Adyen.PaymentSessionResponse) => {
          emit('paymentError')
          if (result) {
            console.error(result.resultCode)
          }
          checkoutStore.loading = false
        },
        onError: onAdyenSessionError,
      }

      const checkout = await AdyenCheckout(checkoutConfiguration)
      return checkout
    }
    catch (e) {
      console.log('error', e)
      checkoutStore.loading = false
    }
  }

  const setUpPaypalExpress = (checkout: any) => {
    const cartStore = useCartStore()
    const paypalConfiguration: PayPalConfiguration = {
      isExpress: true,
      userAction: 'continue',
      blockPayPalVenmoButton: true,
      blockPayPalCreditButton: true,
      blockPayPalPayLaterButton: true,

      onShippingAddressChange: async (data: any, actions: any, component: any) => {
        console.log('on change')
        const selectedShippingOption = data.selectedShippingOption

        const deliveryMethods = await adyenSupportFunctions.createShippingMethodListPayPal(data.shippingAddress.countryCode, component.props.amount.currency, selectedShippingOption !== undefined ? selectedShippingOption.id : undefined)
        const deliveryAmount = selectedShippingOption ? selectedShippingOption.amount.value : deliveryMethods[0].amount.value
        store.setState({ SHOPPER_SHIPPING_COUNTRY_CODE: data.shippingAddress.countryCode })

        const body = {
          pspReference: store.getState().pspReference,
          paymentData: component.paymentData.paymentData ? component.paymentData.paymentData : component.paymentData,
          amount: { value: component.props.amount.value + deliveryAmount, currency: component.props.amount.currency },
          deliveryMethods,
        }

        store.setState({ shippingAddress: data.shippingAddress })

        const newPaymentData = await $fetch('/api/adyen-session-payment/update-order', { method: 'POST', body })
        await cartStore.addShippingMethodToCart(selectedShippingOption ? selectedShippingOption.id : deliveryMethods[0].reference as ShippingMethodName)

        component.updatePaymentData(newPaymentData)
      },

      onShippingOptionsChange: async (data: any, actions: any, component: any) => {
        const selectedShippingOption = data.selectedShippingOption
        const deliveryMethods = await adyenSupportFunctions.createShippingMethodListPayPal(store.getState().SHOPPER_SHIPPING_COUNTRY_CODE, component.props.amount.currency, selectedShippingOption.id)
        const deliveryAmount = selectedShippingOption.amount.value * 100

        const body = {
          pspReference: store.getState().pspReference,
          paymentData: component.paymentData.paymentData ? component.paymentData.paymentData : component.paymentData,
          amount: { value: component.props.amount.value + deliveryAmount, currency: component.props.amount.currency },
          deliveryMethods,
        }

        const newPaymentData = await $fetch('/api/adyen-session-payment/update-order', { method: 'POST', body })
        await cartStore.addShippingMethodToCart(selectedShippingOption.id as ShippingMethodName)

        component.updatePaymentData(newPaymentData)
      },

      onAuthorized(data, actions) {
        console.log('onAuthorized', data)
        actions.resolve()
      },
    }

    const paypal = new PayPal(checkout, paypalConfiguration)
    return paypal
  }

  return {
    orderLoading,
    session,
    expressOrderApplePay,

    setSession,
    setSessionFromLocalStorage,
    updateSessionPaymentMethod,
    restoreOrCreateSession,
    createOrderSession,
    confirmOrder,
    createAdvancedFlowApplePay,
    setUpApplePayExpress,
    setUpPaypalExpress,
    createAdvancedFlowPaypal,
  }
})
