import { cartItemToLineItem } from './cartItemToLineItem'
import type { TaxForOrder } from '~/models/order/taxes'
import type { Order } from '~/types/models/order'
import type { CreateOrderParams } from '~/types/vendors/taxjar'

export function orderToCreateOrder(order: Order, taxes: TaxForOrder): CreateOrderParams {
  const address = order.address.shipping || order.address.billing
  console.log('🚀 orderToCreateOrder ~ address:', address)
  const country = order.market.countryCode

  return {
    transaction_id: order.id,
    transaction_date: order.createdAt,
    to_country: country,
    to_zip: address.postCode,
    to_city: address.city,
    to_state: address.region,
    shipping: order.priceShipping,
    sales_tax: taxes.total,
    amount: order.priceTotal,
    line_items: order.items.map(item => ({
      ...cartItemToLineItem(item),
      sales_tax: taxes.lineItems.find(tax => tax.id === item.id)?.amount || 0,
    }),
    ),
  }
}
