import { cartItemToLineItem } from './cartItemToLineItem'
import type { Order } from '~/types/models/order'
import type { TaxParams } from '~/types/vendors/taxjar'

export function orderToForOrder(order: Order): TaxParams {
  const address = order.address.shipping || order.address.billing
  console.log('🚀 ~ address:', address)
  const country = order.market.countryCode

  return {
    to_country: country,
    to_zip: address.postCode,
    to_city: address.city,
    to_state: address.region,
    shipping: order.priceShipping,
    line_items: order.items.map(cartItemToLineItem),
  }
}
