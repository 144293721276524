import type { CartItem } from '~/types/cart'
import type { TaxLineItem } from '~/types/vendors/taxjar'

export function cartItemToLineItem(cartItem: CartItem): TaxLineItem {
  return {
    id: cartItem.id,
    quantity: cartItem.quantity,
    product_tax_code: '20010',
    unit_price: cartItem.price.final,
  }
}
