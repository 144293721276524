import { type TaxForOrder, TaxForOrderSchema } from '~/models/order/taxes'
import type { TaxForOrderRes } from '~/types/vendors/taxjar'

export function normalizeTaxes(tax: TaxForOrderRes): TaxForOrder {
  return TaxForOrderSchema.parse({
    total: tax.tax.amount_to_collect,
    lineItems: tax.tax.breakdown?.line_items?.map(lineItem => ({
      id: lineItem.id,
      amount: lineItem.tax_collectable,
    })) || [],
  })
}
