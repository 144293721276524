import {
  getAdyenEnvironment,
  getAdyenErrorMessage,
} from 'lib/adyen'
import type { Environment } from 'types/environment'
import { adyenTranslations } from '~/data/adyen-translations'
import { normalizeShippingMethod } from '~/lib/vendors/apple/apple-pay'

export function useAdyenSupportFunctions(emit?: any) {
  const {
    public: { adyenEnvironment },
  } = useRuntimeConfig()
  const router = useRouter()
  const { $checkoutUrl, $t } = useNuxtApp()
  const route = useRoute()
  const checkoutStore = useCheckoutStore()
  const gtm = useGTM()
  const storefrontStore = useStorefrontStore()
  const { countryCode } = storefrontStore.currentMarket
  const context = useNuxtApp()
  async function handleServerResponse(res: Adyen.PaymentSessionResponse, component: any) {
    console.log(res)
    if (res.action) {
      console.log('res has action')
      component.handleAction(res.action)
    }
    else {
      switch (res.resultCode) {
        case 'Authorised':
          await router.push($checkoutUrl({ route, view: 'success', orderId: '' }))
          break
        case 'Received':
        case 'Pending':
          checkoutStore.loading = false
          await router.push($checkoutUrl({ route, view: 'success', orderId: '' }))
          break
        case 'Refused':
        case 'Cancelled':
          gtm.pushPaymentCancelEvent()
          checkoutStore.loading = false
          emit('paymentError', getAdyenErrorMessage(res.resultCode, $t))
          console.error(res)
          break
        default:
          console.error(res)
          break
      }
    }
  }

  const getEnvironment = () => getAdyenEnvironment(countryCode, adyenEnvironment)

  const getAdyenLanguage = (localHTML: string) => {
    const lang = localHTML.split('-')[0]
    return adyenTranslations[lang]
  }

  const createShippingMethodListApplePay = async (countryCode: Environment.CountryCode) => {
    const newShippingMethods = await context.$cart.listShippingMethods(countryCode)
    console.log('🚀 ~ createShippingMethodListApplePay ~ newShippingMethods:', newShippingMethods)

    const shippingMethodList = newShippingMethods.map(normalizeShippingMethod)
    console.log('🚀 ~ createShippingMethodListApplePay ~ shippingMethodList:', shippingMethodList)

    return shippingMethodList
  }

  const createShippingMethodListPayPal = async (countryCode: Environment.CountryCode, currency: string, selectedId?: string) => {
    const newShippingMethods = await context.$cart.listShippingMethods(countryCode)
    const selectedMethod = selectedId ? newShippingMethods.filter(item => item.id === selectedId)[0] : newShippingMethods[0]
    const shippingMethodList = []

    for (const shippingMethod of newShippingMethods) {
      shippingMethodList.push({
        reference: shippingMethod.id,
        description: shippingMethod.deliveryDetails,
        amount: { value: Math.round(shippingMethod.price * 100), currency },
        selected: shippingMethod === selectedMethod,
        type: 'Shipping',
      },
      )
    }
    return shippingMethodList
  }

  return {
    handleServerResponse,
    getEnvironment,
    getAdyenLanguage,
    createShippingMethodListApplePay,
    createShippingMethodListPayPal,
  }
}
