import type * as Contact from 'types/models/contact.model'
import type * as Brink from 'types/vendors/brink'
import type { Environment } from 'types/environment'
import { useLastRawCart } from './useLastRawCart'
import { normalizeBrinkPrice, normalizeCartItems } from '~/lib/brink'
import { DEFAULT_CHECKOUT_INFORMATION } from '~/lib/checkout'
import { getMarket, getStorefront } from '~/lib/storefronts'
import { getShippingMethodLineItem, normalizeAddress, normalizeApplePayTotal, normalizeCartItem, normalizeShippingMethod } from '~/lib/vendors/apple/apple-pay'
import { getItemsCount } from '~/models/cart/getItemsCount'
import { getPriceDiscount } from '~/models/cart/getPriceDiscount'
import { getPriceProducts } from '~/models/cart/getPriceProducts'
import { getDefaultOrder } from '~/models/checkout/order'
import type { CartItem, ShippingMethod } from '~/types/cart'
import type { CheckoutInformation } from '~/types/checkout'
import { WAREHOUSE_ERROR } from '~/configuration/global.configuration'
import type { TaxForOrderRes, TaxParams } from '~/types/vendors/taxjar'
import { orderToForOrder } from '~/lib/vendors/taxjar/orderToForOrder'
import { normalizeTaxes } from '~/lib/vendors/taxjar/normalizeTaxes'
import { DEFAULT_TAXES_OBJECT, type TaxForOrder } from '~/models/order/taxes'
import { orderToCreateOrder } from '~/lib/vendors/taxjar/orderToCreateOrder'

function isAppleShippingMethod(option: any): option is ApplePayJS.ApplePayLineItem {
  return Reflect.has(option, 'label')
}

export function useApplePayOrder() {
  const order = useState('applePayExpressOrder', () => getDefaultOrder())
  const shippingMethods = useState<ShippingMethod[]>('applePayShippingMethods', () => [])
  const checkoutInformation = useState<CheckoutInformation>('applePayCheckoutInformation', () => DEFAULT_CHECKOUT_INFORMATION)
  const forceRefetchShipping = useState<boolean>('applePayCartAmountChanged', () => false)
  const taxes = useState<TaxForOrder>('applePayTaxes', () => (DEFAULT_TAXES_OBJECT))

  function setItems(_currency?: Environment.Currency['code']) {
    const currency = _currency || order.value.currency
    console.log('🚀 ~ setItems ~ setItems:', currency)
    const lastRawCart = useLastRawCart().lastRawCart.value
    if (!lastRawCart) {
      console.error('Cart not found')
      return
    }

    const items = normalizeCartItems(lastRawCart.cartItems, currency)
    const itemsCount = getItemsCount(items)
    const priceProducts = getPriceProducts(items)
    const priceTotal = priceProducts + order.value.priceShipping
    const priceDiscount = getPriceDiscount(items)

    if (priceProducts !== order.value.priceProducts) {
      forceRefetchShipping.value = true
    }

    order.value = {
      ...order.value,
      items,
      itemsCount,
      priceProducts,
      priceTotal,
      priceDiscount,
    }
  }
  async function setMarket(countryCode?: Environment.CountryCode) {
    console.log('🚀 ~ setMarket ~ countryCode:', countryCode)
    if (countryCode) {
      const market = getMarket(countryCode)
      const currency = market.currency.code
      const storefront = getStorefront(market.storefrontCode)
      const currentMarket = order.value.market

      const isDifferentWarehouse = currentMarket.warehouse.region !== market.warehouse.region

      if (isDifferentWarehouse) {
        throw new Error(WAREHOUSE_ERROR)
      }

      const cartStore = useCartStore()
      await cartStore.updateCountryCode(countryCode)

      const isDifferentCurrency = order.value.currency !== currency
      console.log('🚀 ~ setMarket ~ isDifferentCurrency:', isDifferentCurrency)

      if (isDifferentCurrency) {
        setItems(currency)
        // refreshShippingMethod()
      }

      order.value = {
        ...order.value,
        market,
        currency,
        storefront,
      }

      return
    }

    const storefrontStore = useStorefrontStore()

    order.value = {
      ...order.value,
      market: storefrontStore.currentMarket,
      currency: storefrontStore.currency.code,
      storefront: storefrontStore.current,
    }
  }

  function setAddress(payload: { address: Contact.Address, information: Contact.Information }) {
    const { address, information } = payload

    checkoutInformation.value = {
      address: {
        billing: address,
        shipping: address,
        useShippingForBilling: true,
      },
      contact: information,
    }

    order.value = {
      ...order.value,
      address: {
        billing: address,
        shipping: address,
      },
      contact: {
        firstName: address.firstName,
        lastName: address.lastName,
        email: information.email,
        telephone: information.telephone,
      },
    }
  }

  function setAppleShippingAddress(payload: ApplePayJS.ApplePayPaymentContact) {
    console.log('🚀 ~ setAppleShippingAddress ~ payload:', payload)
    const address = normalizeAddress(payload)
    console.log('🚀 ~ setAppleShippingAddress ~ address:', address)
    setAddress(address)
  }

  function setAppleBillingAddress(payload: ApplePayJS.ApplePayPaymentContact) {
    const { address } = normalizeAddress(payload)
    console.log('🚀 ~ setAppleBillingAddress ~ address:', address)
    order.value.address.billing = address
    checkoutInformation.value.address.billing = address
    checkoutInformation.value.address.useShippingForBilling = false
  }

  async function setShippingMethod(payload: ShippingMethod | ApplePayJS.ApplePayLineItem) {
    const shippingMethod = resolveShippingMethod(payload)

    if (!shippingMethod) {
      console.error('Shipping method not found')
      return
    }

    const priceTotal = order.value.priceProducts + shippingMethod.price

    order.value = {
      ...order.value,
      shippingMethod: {
        name: shippingMethod.title,
        estimatedDeliveryTime: shippingMethod.deliveryDetails,
      },
      priceShipping: shippingMethod.price,
      priceTotal,
    }
  }

  async function fetchShippingMethods(_countryCode?: Environment.CountryCode) {
    const context = useNuxtApp()
    const countryCode = _countryCode || order.value.market.countryCode
    const market = order.value.market
    const shippingMethods = await context.$cart.listShippingMethods(countryCode, { market }) as ShippingMethod[]
    setShippingMethods(shippingMethods)
    const applePayShippingMethods = shippingMethods.map(normalizeShippingMethod)
    console.log('🚀 ~ fetchShippingMethods ~ applePayShippingMethods:', applePayShippingMethods)

    const currentShippingMethod = getShippingMethod()?.shippingMethod
    console.log('🚀 ~ fetchShippingMethods ~ currentShippingMethod:', currentShippingMethod)
    const isShippingMethodAvailable = currentShippingMethod && shippingMethods.some(method => (method.id === currentShippingMethod.id) && (method.price === currentShippingMethod.price))
    console.log('🚀 ~ fetchShippingMethods ~ isShippingMethodAvailable:', isShippingMethodAvailable)

    if (!isShippingMethodAvailable) {
      const defaultShippingMethod = shippingMethods[0]
      console.log('🚀 ~ fetchShippingMethods ~ defaultShippingMethod:', defaultShippingMethod)
      await setShippingMethod(defaultShippingMethod)
    }

    return {
      shippingMethods,
      applePayShippingMethods,
    }
  }

  function setShippingMethods(data: ShippingMethod[]) {
    shippingMethods.value = data
  }

  function getAppleShippingMethods() {
    return shippingMethods.value.map(normalizeShippingMethod)
  }

  function getShippingMethodByName(name: string) {
    return shippingMethods.value.find(method => method.title === name)
  }

  function resolveShippingMethod(option: ShippingMethod | ApplePayJS.ApplePayLineItem) {
    if (isAppleShippingMethod(option)) {
      return getShippingMethodByName(option.label)
    }

    return option
  }

  function getItems() {
    const items = order.value.items
    const applePayItems = items.map(normalizeCartItem)

    return {
      items,
      applePayItems,
    }
  }

  function getShippingMethod() {
    const shippingMethod = getShippingMethodByName(order.value.shippingMethod.name)

    if (!shippingMethod) {
      return
    }

    const applePayShippingMethod = normalizeShippingMethod(shippingMethod)
    console.log('🚀 ~ getShippingMethod ~ applePayShippingMethod:', applePayShippingMethod)
    return {
      shippingMethod,
      applePayShippingMethod,
    }
  }

  function getAllAppleLineItems() {
    const { applePayItems } = getItems()
    const applePayShippingMethod = getShippingMethod()?.applePayShippingMethod

    if (!applePayShippingMethod) {
      return applePayItems
    }

    const shippingLineItem = getShippingMethodLineItem(applePayShippingMethod)
    const taxesLineItems = taxes.value.lineItems.map(tax => ({
      label: `Tax ${tax.id}`,
      amount: tax.amount.toString(),
    }))

    return [...applePayItems, shippingLineItem, ...taxesLineItems]
  }

  function getTotal() {
    const { priceProducts, priceShipping } = order.value
    const taxAmount = taxes.value.total
    const totalPrice = priceProducts + priceShipping + taxAmount
    const applePayTotal = normalizeApplePayTotal(totalPrice)
    console.log('🚀 ~ getTotal ~ applePayTotal:', applePayTotal)

    return {
      totalPrice,
      applePayTotal,
    }
  }

  watchEffect(() => {
    console.log('🚀 ~ order.value', order.value)
  })

  watchEffect(() => {
    console.log('🚀 ~ checkoutInformation.value', checkoutInformation.value)
  })

  watchEffect(() => {
    console.log('🚀 ~ shippingMethods.value', shippingMethods.value)
  })

  watchEffect(() => {
    console.log('🚀 ~ taxes.value', taxes.value)
  })

  function shouldUpdateShippingMethods(country?: Environment.CountryCode) {
    if (forceRefetchShipping.value) {
      forceRefetchShipping.value = false
      return true
    }

    if (shippingMethods.value.length) {
      return true
    }

    if (!country) {
      return false
    }

    return order.value.market.countryCode !== country
  }

  function isDifferentWarehouse() {
    const cartStore = useCartStore()
    const countryCodeCart = cartStore.state.countryCode

    if (!countryCodeCart) {
      return
    }

    const cartMarket = getMarket(countryCodeCart)
    const applePayMarket = order.value.market

    const isSameWarehouse = cartMarket.warehouse.region === applePayMarket.warehouse.region

    return !isSameWarehouse
  }

  async function updateBrinkCart() {
    const cartStore = useCartStore()
    const shippingMethod = getShippingMethod()?.shippingMethod

    if (!shippingMethod) {
      return
    }

    // await cartStore.updateCountryCode(order.value.market.countryCode)
    await cartStore.addShippingMethodToCart(shippingMethod.id)
  }

  function saveOrder(_order: Brink.Order) {
    const orderStatus = _order.status ?? _order.statusLog[_order.statusLog.length - 1]?.status ?? order.value.status
    order.value = {
      ...order.value,
      id: _order.id,
      status: orderStatus,
      createdAt: _order.created ?? order.value.createdAt,
      updatedAt: _order.lastUpdated ?? order.value.updatedAt,
      priceTax: normalizeBrinkPrice(_order.orderTaxAmount, _order.currencyUnit),
      payment: {
        method: 'ApplePayExpress',
        status: orderStatus,
      },
      externalTaxAmount:
        _order.externalTaxRate && normalizeBrinkPrice(_order.orderTaxAmount, _order.currencyUnit),
    }

    useOrderStore().setSession({
      session: {
        id: _order.id,
        sessionData: 'dummy',
      },
      order: order.value,
    })

    forceRefetchShipping.value = true
  }

  function resetCartCountryCode() {
    const cartStore = useCartStore()
    const storefrontStore = useStorefrontStore()
    const countryCode = storefrontStore.currentMarket.countryCode
    cartStore.updateCountryCode(countryCode)
  }

  async function calculateTaxes() {
    const country = order.value.market.countryCode
    console.log('🚀 ~ calculateTaxes ~ country:', country)

    if (country !== 'US')
      return

    const body = orderToForOrder(order.value)
    console.log('🚀 ~ calculateTaxes ~ body:', body)

    const resp = await $fetch<TaxForOrderRes>('/api/tax/forOrder', {
      method: 'POST',
      body,
    })
    console.log('🚀 ~ calculateTaxes ~ itemTaxes:', resp)

    taxes.value = normalizeTaxes(resp)
    console.log('🚀 ~ calculateTaxes ~ taxes:', taxes.value)
  }

  async function reportTaxTransaction() {
    const country = order.value.market.countryCode
    console.log('🚀 ~ reportTaxTransaction ~ country:', country)

    if (country !== 'US')
      return

    const body = orderToCreateOrder(order.value, taxes.value)
    console.log('🚀 ~ reportTaxTransaction ~ body:', body)

    const resp = await $fetch<TaxForOrderRes>('/api/tax/createOrder', {
      method: 'POST',
      body,
    })
    console.log('🚀 ~ reportTaxTransaction ~ itemTaxes:', resp)
  }

  return {
    order,
    checkoutInformation,

    getItems,
    getShippingMethod,
    getTotal,
    getAllAppleLineItems,
    shouldUpdateShippingMethods,
    isDifferentWarehouse,

    setItems,
    setMarket,
    setAddress,
    getAppleShippingMethods,
    setAppleShippingAddress,
    setAppleBillingAddress,
    setShippingMethod,
    setShippingMethods,

    updateBrinkCart,
    saveOrder,
    resetCartCountryCode,
    calculateTaxes,
    reportTaxTransaction,

    fetchShippingMethods,

  }
}
